.icon-link {
	font-size: inherit;
	margin: 5px 0.8em;
}

.social-links {
	line-height: 1.8;
}

#resume {
	cursor: pointer;
}

@media (max-width: 590px) {
	.icon-link {
		margin: 5px 0.3em;
	}
}
