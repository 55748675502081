.image-container {
	position: relative;
}

.image {
	border-radius: 15px;
}

.underlay {
	position: absolute;
	bottom: 15px;
	right: 15px;
	height: 100%;
	width: 100%;
	background-color: #190061;
	border-radius: 15px;
	z-index: -1;
}

.overlay {
	position: absolute;
	top: 15px;
	left: 15px;
	height: 100%;
	width: 100%;
	opacity: 0.5;
	transition: 0.5s ease;
	background-color: #000000;
	border-radius: 15px;
	z-index: -1;
}

.overlay:hover {
	opacity: 0;
}
