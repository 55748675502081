@import url("https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap");

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: "Lato", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #0c0032;
	color: #f1f1f1;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h2 {
	margin: 30px;
	font-size: 2em;
	color: #53cbbf;
}

p {
	font-size: 1em;
	color: #f1f1f1;
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	line-height: 1.6;
}

section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 60px 40px;
}

::selection {
	background: #3500d3;
	color: #f1f1f1;
}

.highlighted-text {
	color: #53cbbf;
}

@media (max-width: 590px) {
	h2 {
		margin: 0px;
		font-size: 1.8em;
	}

	section {
		padding: 40px 10px;
	}
}
