/*$deep-purple: #0c0032;
$purple: #190061;
$deep-blue: #240090;
$blue: #3500d3;
$gray: #282828;
$tangerine: #F28500*/

#navbar {
	width: 100vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: black;
	box-shadow: 0 5px 2px -2px rgb(0 0 0 / 40%);
	padding: 10px 0px;
}

#nav-logo {
	margin-left: 40px;
}

#nav-list {
	list-style: none;
	display: flex;
	box-sizing: border-box;
	padding-right: 60px;
}

.nav-item {
	margin-left: 5vw;
}

a {
	text-decoration: none;
	color: #f1f1f1;
	font-size: 1.2em;
	transition: 0.5s;
}

a:hover {
	color: #3500d3;
}

@media (max-width: 590px) {
	#nav-list {
		display: none;
	}

	#navbar {
		position: absolute;
		background-color: #00000000;
		box-shadow: none;
	}

	#nav-logo {
		margin-left: 20px;
	}
}
