@import url(https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap);
html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: "Lato", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #0c0032;
	color: #f1f1f1;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h2 {
	margin: 30px;
	font-size: 2em;
	color: #53cbbf;
}

p {
	font-size: 1em;
	color: #f1f1f1;
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	line-height: 1.6;
}

section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 60px 40px;
}

::selection {
	background: #3500d3;
	color: #f1f1f1;
}

.highlighted-text {
	color: #53cbbf;
}

@media (max-width: 590px) {
	h2 {
		margin: 0px;
		font-size: 1.8em;
	}

	section {
		padding: 40px 10px;
	}
}

.App {
	text-align: center;
}

/*$deep-purple: #0c0032;
$purple: #190061;
$deep-blue: #240090;
$blue: #3500d3;
$gray: #282828;
$tangerine: #F28500*/

#navbar {
	width: 100vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: black;
	box-shadow: 0 5px 2px -2px rgb(0 0 0 / 40%);
	padding: 10px 0px;
}

#nav-logo {
	margin-left: 40px;
}

#nav-list {
	list-style: none;
	display: flex;
	box-sizing: border-box;
	padding-right: 60px;
}

.nav-item {
	margin-left: 5vw;
}

a {
	text-decoration: none;
	color: #f1f1f1;
	font-size: 1.2em;
	transition: 0.5s;
}

a:hover {
	color: #3500d3;
}

@media (max-width: 590px) {
	#nav-list {
		display: none;
	}

	#navbar {
		position: absolute;
		background-color: #00000000;
		box-shadow: none;
	}

	#nav-logo {
		margin-left: 20px;
	}
}

#home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
}

/* Style the video: 100% width and height to cover the entire window */
#my-video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.content {
	color: #f1f1f1;
	width: 70vw;
}

#name {
	font-size: 3em;
	margin: 0.5em;
	color: #53cbbf;
}

#currently-working-on {
	font-size: 1.5em;
}

/*.statue {
	position: absolute;
	width: 318px;
	height: 400px;
	z-index: -1;
}*/

#socials-bar-home {
	font-size: 1.8em;
}

@media (max-width: 590px) {
	.content {
		content: 90vw;
		position: relative;
		bottom: 2em;
	}

	#name {
		font-size: 1.8em;
		margin: 0.5em;
		color: #53cbbf;
	}

	#currently-working-on {
		font-size: 1.2em;
	}
}

.scrolldown-container {
	display: none;
}

@media (max-width: 590px) {
	.scrolldown-container {
		position: absolute;
		bottom: 100px;
		width: 24px;
		height: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.chevron {
		position: absolute;
		width: 28px;
		height: 8px;
		opacity: 0;
		transform: scale3d(0.5, 0.5, 0.5);
		animation: move 3s ease-out infinite;
	}

	.chevron:first-child {
		animation: move 3s ease-out 1s infinite;
	}

	.chevron:nth-child(2) {
		animation: move 3s ease-out 2s infinite;
	}

	.chevron:before,
	.chevron:after {
		content: " ";
		position: absolute;
		top: 0;
		height: 100%;
		width: 51%;
		background: #f1f1f1;
	}

	.chevron:before {
		left: 0;
		transform: skew(0deg, 30deg);
	}

	.chevron:after {
		right: 0;
		width: 50%;
		transform: skew(0deg, -30deg);
	}

	@keyframes move {
		25% {
			opacity: 1;
		}
		33% {
			opacity: 1;
			transform: translateY(30px);
		}
		67% {
			opacity: 1;
			transform: translateY(40px);
		}
		100% {
			opacity: 0;
			transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
		}
	}

	@keyframes pulse {
		to {
			opacity: 1;
		}
	}
}

.icon-link {
	font-size: inherit;
	margin: 5px 0.8em;
}

.social-links {
	line-height: 1.8;
}

#resume {
	cursor: pointer;
}

@media (max-width: 590px) {
	.icon-link {
		margin: 5px 0.3em;
	}
}

#story {
	width: 70vw;
	display: flex;
	flex-direction: row;
	text-align: left;
	align-items: center;
	justify-content: center;
}

#portrait {
	margin-left: 60px;
}

.list {
	columns: 3;
	list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAPCAYAAADZCo4zAAAACXBIWXMAAAsSAAALEgHS3X78AAAA2klEQVQYlV2QsWoCQRCGPw8xLxCwtUhhabGFXbYRbMUDfYc0vkK6dLH0BQKBbGtj49kpXpUHEO2ENIo2qS6MzJq5+2HZf/f/Z5h/akVREJHm2QJo8I993YhjoEcZs8Q8JxVxE5z/TLS6BXSMeAFGQmKHKfBgDF/B+YOQ25Bpnv0Ajyr+Amvl+3qaZ29GRDs9q3EmKZ6AlYptoKk8kyGreziq4Qy0gvOne0xNEqtfRbQpBF29d8H59/hpDX3gCgzstqxBFjUPzn+X9ilDyhlulx+R22M7vJQqBcAfcBhQkvc2zjgAAAAASUVORK5CYII=);
	-webkit-padding-start: 15px;
	        padding-inline-start: 15px;
}

.list > li {
	padding-left: 0.1em;
	margin-bottom: 0.2em;
}

@media (max-width: 768px) {
	#story {
		flex-direction: column;
	}

	#portrait {
		margin-left: 0px;
		margin-top: 30px;
	}
}

.image-container {
	position: relative;
}

.image {
	border-radius: 15px;
}

.underlay {
	position: absolute;
	bottom: 15px;
	right: 15px;
	height: 100%;
	width: 100%;
	background-color: #190061;
	border-radius: 15px;
	z-index: -1;
}

.overlay {
	position: absolute;
	top: 15px;
	left: 15px;
	height: 100%;
	width: 100%;
	opacity: 0.5;
	transition: 0.5s ease;
	background-color: #000000;
	border-radius: 15px;
	z-index: -1;
}

.overlay:hover {
	opacity: 0;
}

#projects-container {
	width: 80vw;
}

@media (max-width: 768px) {
	#projects {
		padding-bottom: 0px;
	}
}

@media (min-width: 768px) {
	.project:nth-child(odd) {
		flex-direction: row;
	}

	.project:nth-child(even) {
		flex-direction: row-reverse;
	}

	.project:nth-child(odd) .project-image {
		margin-right: 30px;
	}

	.project:nth-child(even) .project-image {
		margin-left: 30px;
	}
}

.project {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.project-content {
	border-radius: 15px;
	background-color: #1d1d1dd1;
	text-align: left;
	padding: 25px;
	width: 100%;
}

.project-name {
	margin: 0.2em 0em;
}

.project-links {
	margin-top: 2em;
}

.project-button {
	margin-right: 1.5em;
	padding: 0.4em 1em;
	border: 1px solid #f1f1f1;
	border-radius: 15px;
	font-size: 1em;
}

.project-button:hover {
	background-color: #f1f1f142;
	color: #f1f1f1;
}

.project-stack {
	display: flex;
	list-style-type: none;
	margin: 0px;
	padding-left: 0px;
	font-family: monospace;
	font-size: 1.1em;
	flex-wrap: wrap;
}

.project-stack > li {
	margin-right: 0.8em;
	margin-bottom: 0.4em;
	padding: 0.2em 0.5em;
	background-color: #3500d370;
	border-radius: 0.2em;
}

@media (max-width: 768px) {
	.project {
		flex-direction: column;
		margin-bottom: 30px;
	}

	.project-content {
		padding-top: 60px;
		position: relative;
		top: -35px;
		width: 90%;
	}

	.project-image {
		margin-right: 0px;
		z-index: 10;
	}
}

#contact {
	padding-top: 20px;
}

#contact-title {
	margin-bottom: 0.2em;
}
#contact-text {
	font-size: 1.1em;
	color: #a9a9a9;
	max-width: 650px;
}

@media (max-width: 768px) {
	#contact {
		padding-top: 0px;
	}

	#contact-text {
		width: 80vw;
	}
}

input,
textarea {
	background-color: #140052;
	color: #f1f1f1;
	padding: 0.5em;
	font-size: 1.1em;
	margin: 10px;
	border: 2px solid #3500d3;
	border-radius: 5px;
	font-family: inherit;
	width: 100%;
	box-sizing: border-box;
}

textarea {
	resize: none;
}

input:focus,
textarea:focus {
	outline: none;
	border-color: #53cbbf;
}

#contact-form {
	max-width: 650px;
}

button {
	background-color: #53cbbf26;
	color: #53cbbf;
	padding: 0.5em 2em;
	font-size: 1.1em;
	margin: 10px;
	text-align: left;
	border: 2px solid #53cbbf;
	border-radius: 25px;
	font-family: inherit;
	transition: 0.5s;
	cursor: pointer;
}

button:hover {
	background-color: #53cbbf4f;
}

button:active {
	background-color: #53cbbf4f;
	transform: translateY(5px);
}

@media (max-width: 768px) {
	#contact-form {
		width: 80vw;
	}
}

#footer {
	width: 100vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: black;
	padding: 10px 60px;
	box-sizing: border-box;
}

#copyright {
	display: flex;
	align-items: center;
	box-sizing: border-box;
}

#footer-text {
	margin-left: 10px;
	box-sizing: border-box;
}

#socials-bar-footer {
	font-size: 1.4em;
}

@media (max-width: 590px) {
	#footer {
		flex-direction: column;
	}
	#socials-bar-footer {
		margin: 0.8em;
	}
}

.experience {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.experience-content {
	border-radius: 15px;
	background-color: #ffffff29;
	text-align: left;
	padding: 25px;
	width: 100%;
}

.experience-logo {
	margin-right: 30px;
}

.experience-name {
	margin: 0px;
	display: flex;
	flex-direction: row;
	grid-gap: 5px;
	gap: 5px;
}

.experience-dates {
	font-family: monospace;
	font-size: 1.2em;
	margin: 0px;
	text-align: center;
}

.experience-name-and-dates {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.experience-stack {
	display: flex;
	list-style-type: none;
	margin: 0px;
	padding-left: 0px;
	font-family: monospace;
	font-size: 1.1em;
	flex-wrap: wrap;
}

.experience-stack > li {
	margin-right: 0.8em;
	margin-bottom: 0.4em;
	padding: 0.2em 0.5em;
	border-radius: 0.2em;
}

.experience-link {
	font-size: inherit;
	text-decoration: underline;
	color: #53cbbf;
}

.experience-link:hover {
	color: white;
}

@media (max-width: 768px) {
	.experience {
		flex-direction: column;
		margin-bottom: 30px;
	}

	.experience-content {
		padding-top: 60px;
		position: relative;
		top: -35px;
		width: 90%;
	}

	.experience-logo {
		margin-right: 0px;
		z-index: 10;
	}

	.experience-name-and-dates {
		flex-direction: column;
	}

	.experience-name {
		flex-direction: column;
		align-items: center;
		margin-bottom: 1em;
	}

	.experience-stack {
		justify-content: center;
	}

	.experience-desc {
		text-align: center;
	}
}

