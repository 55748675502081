@media (min-width: 768px) {
	.project:nth-child(odd) {
		flex-direction: row;
	}

	.project:nth-child(even) {
		flex-direction: row-reverse;
	}

	.project:nth-child(odd) .project-image {
		margin-right: 30px;
	}

	.project:nth-child(even) .project-image {
		margin-left: 30px;
	}
}

.project {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.project-content {
	border-radius: 15px;
	background-color: #1d1d1dd1;
	text-align: left;
	padding: 25px;
	width: 100%;
}

.project-name {
	margin: 0.2em 0em;
}

.project-links {
	margin-top: 2em;
}

.project-button {
	margin-right: 1.5em;
	padding: 0.4em 1em;
	border: 1px solid #f1f1f1;
	border-radius: 15px;
	font-size: 1em;
}

.project-button:hover {
	background-color: #f1f1f142;
	color: #f1f1f1;
}

.project-stack {
	display: flex;
	list-style-type: none;
	margin: 0px;
	padding-left: 0px;
	font-family: monospace;
	font-size: 1.1em;
	flex-wrap: wrap;
}

.project-stack > li {
	margin-right: 0.8em;
	margin-bottom: 0.4em;
	padding: 0.2em 0.5em;
	background-color: #3500d370;
	border-radius: 0.2em;
}

@media (max-width: 768px) {
	.project {
		flex-direction: column;
		margin-bottom: 30px;
	}

	.project-content {
		padding-top: 60px;
		position: relative;
		top: -35px;
		width: 90%;
	}

	.project-image {
		margin-right: 0px;
		z-index: 10;
	}
}
