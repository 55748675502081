.experience {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.experience-content {
	border-radius: 15px;
	background-color: #ffffff29;
	text-align: left;
	padding: 25px;
	width: 100%;
}

.experience-logo {
	margin-right: 30px;
}

.experience-name {
	margin: 0px;
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.experience-dates {
	font-family: monospace;
	font-size: 1.2em;
	margin: 0px;
	text-align: center;
}

.experience-name-and-dates {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.experience-stack {
	display: flex;
	list-style-type: none;
	margin: 0px;
	padding-left: 0px;
	font-family: monospace;
	font-size: 1.1em;
	flex-wrap: wrap;
}

.experience-stack > li {
	margin-right: 0.8em;
	margin-bottom: 0.4em;
	padding: 0.2em 0.5em;
	border-radius: 0.2em;
}

.experience-link {
	font-size: inherit;
	text-decoration: underline;
	color: #53cbbf;
}

.experience-link:hover {
	color: white;
}

@media (max-width: 768px) {
	.experience {
		flex-direction: column;
		margin-bottom: 30px;
	}

	.experience-content {
		padding-top: 60px;
		position: relative;
		top: -35px;
		width: 90%;
	}

	.experience-logo {
		margin-right: 0px;
		z-index: 10;
	}

	.experience-name-and-dates {
		flex-direction: column;
	}

	.experience-name {
		flex-direction: column;
		align-items: center;
		margin-bottom: 1em;
	}

	.experience-stack {
		justify-content: center;
	}

	.experience-desc {
		text-align: center;
	}
}
