#home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
}

/* Style the video: 100% width and height to cover the entire window */
#my-video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.content {
	color: #f1f1f1;
	width: 70vw;
}

#name {
	font-size: 3em;
	margin: 0.5em;
	color: #53cbbf;
}

#currently-working-on {
	font-size: 1.5em;
}

/*.statue {
	position: absolute;
	width: 318px;
	height: 400px;
	z-index: -1;
}*/

#socials-bar-home {
	font-size: 1.8em;
}

@media (max-width: 590px) {
	.content {
		content: 90vw;
		position: relative;
		bottom: 2em;
	}

	#name {
		font-size: 1.8em;
		margin: 0.5em;
		color: #53cbbf;
	}

	#currently-working-on {
		font-size: 1.2em;
	}
}
