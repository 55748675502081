#footer {
	width: 100vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: black;
	padding: 10px 60px;
	box-sizing: border-box;
}

#copyright {
	display: flex;
	align-items: center;
	box-sizing: border-box;
}

#footer-text {
	margin-left: 10px;
	box-sizing: border-box;
}

#socials-bar-footer {
	font-size: 1.4em;
}

@media (max-width: 590px) {
	#footer {
		flex-direction: column;
	}
	#socials-bar-footer {
		margin: 0.8em;
	}
}
